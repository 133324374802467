// Step 1: Import React
import * as React from 'react'
// import { useScrollRestoration } from 'gatsby'
import Layout from '../components/layout'
// import { StaticImage } from 'gatsby-plugin-image'
import Cate from '../components/portfolio/category'
import Intro from '../components/portfolio/portfolio-intro'
import Courses from '../components/portfolio/courses'
import CallToAct from '../components/portfolio/call-to-act'

// import { graphql } from 'gatsby'

// Step 2: Define your component
const PortfolioPage = ({location}) => {
  let cat = "default"
  if (location.state) {
    cat = location.state.category
  }
  // console.log(cat)
  // console.log(location.state.hasOwnProperty('category'))

  // let category = 'category' in location.state ? location.state.category : "default"
  // let cat = location.state.category !== undefined ? location.state.category : "default" 
  // console.log(cat)
  // console.log(location)
  return (
    <Layout pageTitle="Portfolio Page">
      <Intro />
      <Cate category={cat}/>
      <Courses category={cat}/>
      <CallToAct />
    </Layout>
  )
}
// Step 3: Export your component

export default PortfolioPage