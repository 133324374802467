
// Step 1: Import React
import * as React from 'react'
// import Layout from '../components/layout'
import { Link } from 'gatsby'
import { graphql, useStaticQuery } from 'gatsby'


// Step 2: Define your component
const Category = ({category}) => {
  // console.log(category)
  // console.log(data)
  const data = useStaticQuery(graphql`
    query {
        allWpCategory {
            edges {
              node {
                id
                slug
              }
            }
          }
    }
  `)
//   console.log(data)
  return (
    
    <section className="portfolio-cate">
        <div className="portfolio-cate__list">
            {
                data.allWpCategory.edges.map(edge => {
                    return(
                        <Link to="/portfolio" key={edge.node.id} state={{category: edge.node.slug}} className={`portfolio-cate__item ${edge.node.slug === category ? "portfolio-cate__item-active" : ""}`}>{edge.node.slug.replace(/-/g, " ")}</Link>
                    )
                })
            }

        </div>

    </section>
   

   
  )
}
// Step 3: Export your component
export default Category