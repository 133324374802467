
// Step 1: Import React
import * as React from 'react'
// import Layout from '../components/layout'
import { Link } from 'gatsby'


// Step 2: Define your component
const CallToAction = () => {
  // console.log(data)
  return (
    
    <section className='callToAction'>
        <h2 className="lg-text white-text med">Let's work together!</h2>
        <Link to="/contact" className='btn callToAction__btn'>Get in touch</Link>
    </section>
   

   
  )
}
// Step 3: Export your component
export default CallToAction