
// Step 1: Import React
import * as React from 'react'
// import Layout from '../components/layout'
import {StaticImage} from 'gatsby-plugin-image'



// Step 2: Define your component
const PortfolioIntro = () => {
  // console.log(data)
  return (
    
    <section className='portfolio-intro'>
        <div className="portfolio-intro__coverimg">
            <StaticImage alt={`cover img`} className="coverimg" src='../../images/radek-grzybowski-eBRTYyjwpRY-unsplash.jpg'/>
            <div className="portfolio-intro__bgoverlay"></div>
        </div>

        <div className="portfolio-intro__text">
            <h1 className="heading-lg">Portfolio</h1>
            <p className="paragraph-low-md">Take a look at some of <span className="pink-text">my works</span></p>
        </div>
    
    </section>
   

   
  )
}
// Step 3: Export your component
export default PortfolioIntro