// Step 1: Import React
import * as React from 'react'
// import Layout from '../components/layout'
import { graphql, useStaticQuery } from 'gatsby'
import {GatsbyImage} from 'gatsby-plugin-image'
import { Link } from 'gatsby'

// import Fb from '../../assets/facebook.svg'
// import Gm from '../../assets/google-plus.svg'
// import Li from '../../assets/linkedin2.svg'
// import Illustration from '../../images/brooke-cagle-g1Kr4Ozfoac-unsplash.jpg'


// Step 2: Define your component
const Courses = ({category}) => {
  // console.log(category)


  const data = useStaticQuery(graphql`
    query {
      allWpCourse(sort: {fields: date, order: DESC}) {
        edges {
          node{
            slug
            title
            id
            date(formatString: "MMMM DD, YYYY")
            categories {
              nodes {
                slug
              }
            }
            featuredImage {
              node {
                  localFile {
                      childImageSharp {
                          gatsbyImageData
                      }
                  }
              }
            }
          }
        }
      }
    }
  `)

  // let cate = category !== undefined ? category : "default" 
  let edges = data.allWpCourse.edges.filter(edge=>
    // edge.node.categories.nodes.some(cate => cate.slug === category).length > 0
    edge.node.categories.nodes.some(node => node.slug === category) 
  )
  // console.log(data.allWpCourse.edges)
  // console.log(edges)
  // console
  return (
    
    <section className="courses">
        <h4 className="heading-md courses__text-heading--sm ">My Works</h4>
        <div className="courses__container">
          {
            
            // data.allWpCourse.
            edges.map((edge) => {
              
              return (
                <div key={edge.node.id} className="courses__course">
                  <Link to={`/portfolio/${edge.node.slug}`}>

                    <div className='courses__course-thumbnail'>
                      <GatsbyImage image={edge.node.featuredImage.node.localFile.childImageSharp.gatsbyImageData} alt={`${edge.node.featuredImage.node.altText !== null ? edge.node.featuredImage.node.altText : "this image has no alt"}`}/>
                      <div className="courses__course-bgoverlay"></div>
                    </div>
                    <div className="courses__course-heading">
                      <h1 className="courses__course-title">{edge.node.title}</h1>
                      <div className="courses__course-date">{edge.node.date}</div>
                      {/* <Link to={`/portfolio/${node.slug}`} className="btn">Link to course</Link> */}

                    </div>
                  </Link>
                    
                </div>)
            })
          }
        </div>
        {/* <Link to="/portfolio" className='btn btn-pink courses__btn'>See More</Link> */}
    </section>
   

   
  )
}

// Step 3: Export your component
export default Courses